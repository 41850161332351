import axios from 'api';

export const getAllChannels = () => {
  return axios.get('/inbox/channels/');
};

export const getFbPageTokens = (params) => {
  // console.log('parmas sent to token api', params);
  return axios.post('/inbox/Fb-page-access-tokens/', params);
};

export const createFacebookInbox = async (params) => {
  let result = {};
  try {
    const res = await axios.post('/inbox/facebook-inboxes/', params);
    result = res.data || {};
    return { success: true, data: result };
  } catch (error) {
    return {
      success: false,
      message: error || 'something went wrong'
    };
  }
};

export const getAllInboxes = () => {
  return axios.get('/inbox/inboxes/');
};

export const getAllPsids = async (params, signal) => {
  return axios.get(`/conversations/conversations/${params?.inbox_id}/`, params, { signal: signal });
};

export const getChats = async (params, signal) => {
  return axios.get(`/conversations/messages/${params?.user_psid}/`, params, { signal: signal });
};

//whatsapp inboxes
export const createWhatsAppInbox = async (params) => {
  let result = {};
  try {
    const res = await axios.post('/inbox/whatsapp-inboxes/', params);
    result = res.data || {};
    return { success: true, data: result };
  } catch (error) {
    return {
      success: false,
      message: error || 'something went wrong'
    };
  }
};

//instagram Inboxes
export const createInstagramInbox = async (params) => {
  let result = {};
  try {
    const res = await axios.post('/inbox/instagram-inboxes/', params);
    result = res.data || {};
    return { success: true, data: result };
  } catch (error) {
    return {
      success: false,
      message: error || 'something went wrong'
    };
  }
};
