import React from 'react';
import PropTypes from 'prop-types';

// Utility function to conditionally render a component
const ConditionalComponent = ({ show, children }) => {
  if (show) {
    return <>{children}</>;
  }
  return null;
};

ConditionalComponent.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.object
};
export default ConditionalComponent;
