import { useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Grid, Toolbar, Typography, useMediaQuery } from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import IconButton from 'components/@extended/IconButton';

import { MenuOrientation, ThemeMode } from 'config';
import useConfig from 'hooks/useConfig';
import { dispatch, useSelector } from 'store';
import { openDrawer } from 'store/reducers/menu';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import moment from 'moment';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = () => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { menuOrientation } = useConfig();

  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);

  const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'grey.50' : 'grey.100';
  const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.50';
  const currentDateFormatted = moment().format('dddd, DD MMM YYYY');
  // common header
  const mainHeader = (
    <Toolbar>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              {!isHorizontal ? (
                <IconButton
                  aria-label="open drawer"
                  onClick={() => dispatch(openDrawer(!drawerOpen))}
                  edge="start"
                  color="secondary"
                  variant="light"
                  sx={{ color: 'text.primary', bgcolor: drawerOpen ? iconBackColorOpen : iconBackColor, ml: -1 }}
                >
                  {!drawerOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </IconButton>
              ) : null}
            </Grid>
            <Grid item>
              <Typography fontSize={'16px'} fontWeight={500}>
                {currentDateFormatted}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ justifyContent: 'end' }} item xs={12} sm={6} md={6} lg={6} xl={6}>
          {headerContent}
        </Grid>
      </Grid>
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: 1200,
      width: isHorizontal ? '100%' : drawerOpen ? 'calc(100% - 260px)' : { xs: '100%', lg: 'calc(100% - 60px)' }
      // boxShadow: theme.customShadows.z1
    }
  };

  return (
    <>
      {!downLG ? (
        <AppBarStyled open={drawerOpen} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

export default Header;
