// import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// project import
import CommonLayout from 'layout/CommonLayout';
// import Loadable from 'components/Loadable';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// render - landing page
// const PagesLanding = Loadable(lazy(() => import('pages/landing')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <CommonLayout layout="blank" />,
      children: [
        {
          path: '/',
          element: <Navigate to={'/login'} />
        }
      ]
    },
    LoginRoutes,
    MainRoutes
  ]);
}
