// project import
// import applications from './applications';
// import widget from './widget';
// import formsTables from './forms-tables';
// import chartsMap from './charts-map';
// import other from './other';
// import pages from './pages';

import help from './help';
import modules from './modules';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // items: [widget, applications, formsTables, chartsMap, pages, other]
  items: [modules, help]
};

export default menuItems;
