// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --m: 17.5;
  --wavefreq: calc(100ms * var(--m));
}

.highlighted_text {
  background: #349eea;
  text-align: center;
  margin: 0px 3px;
  padding: 0px 3px;
  font-weight: 600;
  border-radius: 5px;
  color: white;
}

.MuiBox-root.css-19kzrtu {
  padding: 0 24px;
}

.Word {
  margin-right: 4px;
}`, "",{"version":3,"sources":["webpack://./src/assets/third-party/wave.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,kCAAkC;AACpC;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[":root {\n  --m: 17.5;\n  --wavefreq: calc(100ms * var(--m));\n}\n\n.highlighted_text {\n  background: #349eea;\n  text-align: center;\n  margin: 0px 3px;\n  padding: 0px 3px;\n  font-weight: 600;\n  border-radius: 5px;\n  color: white;\n}\n\n.MuiBox-root.css-19kzrtu {\n  padding: 0 24px;\n}\n\n.Word {\n  margin-right: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
