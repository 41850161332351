import React from 'react';
import ReactDOM from 'react-dom/client';
// import { BrowserRouter } from 'react-router-dom';

// third-party
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// scroll bar
import 'simplebar/dist/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// apex-chart
import 'assets/third-party/apex-chart.css';
import 'assets/third-party/react-table.css';

// wave
import 'assets/third-party/wave.css';

// project import
import App from './App';
import { store, persister } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';
import reportWebVitals from './reportWebVitals';
import addAuthTokenInterceptor from 'api/addAuthTokenInterceptor';
import ErrorBoundary from 'components/ErrorBoundary';
import { createBrowserHistory } from 'history';
import { unstable_HistoryRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
const history = createBrowserHistory();

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

addAuthTokenInterceptor(store);
root.render(
  <ErrorBoundary>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <ConfigProvider>
          {/* <BrowserRouter> */}
          <Router history={history}>
            <App />
          </Router>
          {/* </BrowserRouter> */}
        </ConfigProvider>
      </PersistGate>
    </ReduxProvider>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function removeConsoleMethods() {
  console.log = function () {};
  console.info = function () {};
  console.warn = function () {};
  console.error = function () {};
  console.debug = function () {};
  console.assert = function () {};
  console.group = function () {};
  console.groupEnd = function () {};
}
if (process.env.NODE_ENV === 'production') {
  removeConsoleMethods();
}
