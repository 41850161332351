import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
// import Page404 from 'pages/Page404';

// render - dashboard
// const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/default')));

// pages routing
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));

//render - servicepack
const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/analytics')));
const InstragramInbox = Loadable(lazy(() => import('pages/instagram')));
const FacebookInbox = Loadable(lazy(() => import('pages/facebook')));

//inbox Management
const InboxManagement = Loadable(lazy(() => import('pages/inboxManagement/index')));
const InboxManagementCreate = Loadable(lazy(() => import('pages/inboxManagement/create')));
const InstaRedirectionPage = Loadable(lazy(() => import('pages/inboxManagement/instaRedirection')));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <DashboardAnalytics />
        },
        {
          path: 'instagram',
          element: <InstragramInbox />
        },
        { path: 'facebook', element: <FacebookInbox /> },
        {
          path: 'inbox_management',
          children: [
            { path: '', element: <InboxManagement /> },
            { path: 'create', element: <InboxManagementCreate /> }
          ]
        }
        // {
        //   path: 'list',
        //   element: <List />
        // },
        //-------------------------new routes ******
        // {
        //   path: '404',
        //   element: <Page404 />
        // }
      ]
    },
    {
      path: '/auth',
      element: <CommonLayout />,
      children: [
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'register',
          element: <AuthRegister />
        },
        {
          path: 'forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: 'reset-password',
          element: <AuthResetPassword />
        },
        {
          path: 'check-mail',
          element: <AuthCheckMail />
        },
        {
          path: 'code-verification',
          element: <AuthCodeVerification />
        }
      ]
    },
    {
      path: '/',
      element: <CommonLayout layout="blank" />,
      children: [{ path: 'instagram/redirect/', element: <InstaRedirectionPage /> }]
    }
  ]
};

export default MainRoutes;
