import { FormattedMessage } from 'react-intl';
import { Logout, NotificationStatus, MessageQuestion } from 'iconsax-react';
import { DollarOutlined, } from '@ant-design/icons';

const icons = {
  help: MessageQuestion,
  logout: Logout,
  notifications: NotificationStatus,
  DollarOutlined: DollarOutlined
};

const help = {
  id: 'group-help',
  // title: <FormattedMessage id="help" />,
  type: 'group',
  icon: icons.help,
  children: [
    {
      id: 'help',
      title: <FormattedMessage id="help" />,
      type: 'item',
      url: '/help',
      icon: icons.help
    },
    {
      id: 'notifications',
      title: <FormattedMessage id="notifications" />,
      type: 'item',
      url: '/notifications',
      icon: icons.notifications
    },
    {
      id: 'price',
      title: <FormattedMessage id="price" />,
      type: 'item',
      url: '/price',
      icon: icons.DollarOutlined
    },
    {
      id: 'logout',
      title: <FormattedMessage id="logout" />,
      type: 'item',
      // url: '/logout',
      icon: icons.logout
    }
  ]
};

export default help;
