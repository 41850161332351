import axios, { axiosInstanceForRefreshToken } from 'api';

// const captchaValue = recaptcha.current.getValue();
const config = {
  // body: JSON.stringify({ captchaValue }),
  headers: {
    // withCredentials: true
  }
};
export const login = async (payload) => {
  return axios.post(
    `/accounts/api/login/`,
    {
      email: payload.email,
      password: payload.password
    },
    config
  );
};

export const register = async (params) => {
  let result = {};
  console.log('params passed to register', params);
  try {
    const res = await axios.post(`accounts/api/register/`, params);
    result = res.data || {};
    return { success: true, data: result };
  } catch (error) {
    return {
      success: false,
      message: error || 'something went wrong'
    };
  }
};

export const sendResetLink = async (params) => {
  let result = {};
  try {
    const res = await axios.post(`api/account/forgot-password/send-reset-link/`, params);
    result = res.data || {};
    return { success: true, data: result };
  } catch (error) {
    return {
      success: false,
      message: error || 'something went wrong'
    };
  }
};

export const resetPassword = async (params) => {
  let result = {};
  try {
    const res = await axios.post(`api/account/forgot-password/reset-password/`, params);
    result = res.data || {};
    return { success: true, data: result };
  } catch (error) {
    return {
      success: false,
      message: error || 'something went wrong'
    };
  }
};

export const refreshTokenAPI = (params) => {
  const user = {
    refresh: params
  };
  return axiosInstanceForRefreshToken.post(`api/account/refresh_token/`, user);
};

export const reCaptchaVarify = (params) => {
  return axios.post('api/account/captcha_verify/', params);
};
