import React from 'react';

// material-ui
import { Box, Grid, useMediaQuery } from '@mui/material';

// project import
// import Search from './Search';
// import Message from './Message';
import Profile from './Profile';
// import Localization from './Localization';
// import Notification from './Notification';
import Customization from './Customization';
import MobileSection from './MobileSection';
// import MegaMenuSection from './MegaMenuSection';

import { MenuOrientation } from 'config';
import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';
// import Language from './Language';
// import useDrawerConfig from 'hooks/WebConfig/useDrawerConfig';
// import ConditionalComponent from 'components/common/ConditionalComponent';
// import Direction from './Direction';
import MoreOptions from './MoreOptions';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { /* i18n, */ menuOrientation } = useConfig();
  // const { LanguageBox, DirectionBox } = useDrawerConfig();

  const downLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const localization = useMemo(() => <Localization />, [i18n]);

  // const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <>
      <Grid>
        <Grid>{menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}</Grid>
        {/* {!downLG && <Search />} */}
        <Grid container>
          {/* {!downLG && megaMenu} */}
          {/* {!downLG && localization} */}
          {downLG && <Box sx={{ width: '100%', ml: 1 }} />}
          {/* <ConditionalComponent show={DirectionBox}>
            <Direction />
          </ConditionalComponent>
          <ConditionalComponent show={LanguageBox}>
            <Language />
          </ConditionalComponent> */}
          {/* <Notification /> */}
          {/* <Message /> */}
          <Customization />
          {!downLG && <Profile />}
          {!downLG && <MoreOptions />}
          {downLG && <MobileSection />}
        </Grid>
      </Grid>
    </>
  );
};

export default HeaderContent;
