import PropTypes from 'prop-types';
import { useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import useWebConfig from 'hooks/useWebConfig';
// import NotFoundPage from 'pages/Page404';
// project import
import useAuth from 'hooks/useAuth';
import useWebConfig from 'hooks/useWebConfig';
// import usePricing from 'hooks/Pricing';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const { Pages, /* getWebConfigAction, resetWebConfig */ } = useWebConfig();
  // const { getPricingConfigAction } = usePricing();
  const navigate = useNavigate();
  const location = useLocation();

  const isPageVisible = (path, pages) => {
    // const currentPage = pages.find((page) => page.path === path);

    const currentPage = pages.find((page) => path.includes(page.path));
    return currentPage?.visiblity || false;
  };

  useEffect(() => {
    const isVisible = isPageVisible(location.pathname, Pages);
    if (!isLoggedIn) {
      navigate('login', {
        state: {
          from: location.pathname
        },
        replace: true
      });
      navigate('login', { replace: true });
    } else {
      if (!isVisible) {
        if (location.pathname !== '/404') {
          navigate('/404');
        }
      }
    }
  }, [isLoggedIn, navigate, location, Pages]);

  useLayoutEffect(() => {
    // getWebConfigAction();
    // resetWebConfig();
    // getPricingConfigAction();
  }, []);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
