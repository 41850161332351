import axiosInstance from './index';

export default function addAuthTokenInterceptor(store) {
  axiosInstance.interceptors.request.use((req) => {
    const token = store.getState().auth.tokens.access || null;
    // console.log("token ===>", token, req);
    if (!token) return req;
    req.headers['Authorization'] = `Bearer ${token}`;
    return req;
  });
}
