import { FormattedMessage } from 'react-intl';

import { Home2, Instagram, Facebook, DirectInbox } from 'iconsax-react';

const icons = {
  dashboard: Home2,
  instagram: Instagram,
  facebook: Facebook,
  inbox: DirectInbox
};

const modules = {
  id: 'group-modules',
  title: <FormattedMessage id="inbox" />,
  type: 'group',
  icon: icons.CarryOutOutlined,
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.dashboard
    },
    {
      id: 'instagram',
      title: <FormattedMessage id="instagram" />,
      type: 'item',
      url: '/instagram',
      icon: icons.instagram
    },
    {
      id: 'facebook',
      title: <FormattedMessage id="facebook" />,
      type: 'item',
      url: '/facebook',
      icon: icons.facebook
    },
    {
      id: 'inbox_management',
      title: <FormattedMessage id="inbox_management" />,
      type: 'item',
      url: '/inbox_management',
      icon: icons.inbox
    }
  ]
};

export default modules;
