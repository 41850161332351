import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as webConfigAPI from 'api/Webconfig';

const initialState = {
  Pages: [
    { path: '/dashboard', visiblity: true },
    { path: '/instagram', visiblity: true },
    { path: '/facebook', visiblity: true },
    { path: '/inbox_management', visiblity: true },
    { path: '/price/payouts', visiblity: true }
  ],
  Scorecard: {
    Title: true,
    Search: true,
    DownloadReport: true,
    AdvanceFilter: true,
    AppliedFilter: true,
    DateRange: true,
    SelectLob: true,
    SelectTeam: true,
    SelectAgent: true,
    ClearAll: true,
    Addcolumn: true,
    DataGrid: {
      Lob: true,
      TotalCalls: true,
      CQ: true,
      CSAT: true,
      CompletedCalls: true,
      InsuffTransCalls: true,
      ProcessedCalls: true,
      action: true,
      Opening: true,
      OnHold: true,
      Action: true
    }
  },
  Inspect: {
    AdvanceFilter: true,
    AppliedFilter: true,
    DateRange: true,
    SelectLob: true,
    SelectTeam: true,
    SelectAgent: true,
    ClearAll: true
  },
  Drawer: {
    dashboard: true,
    scorecard: true,
    analytics: true,
    evaluation: true,
    livecall: true,
    inspect: true,
    settings: true,
    help: true,
    notification: true,
    logout: true,
    price: true
  },
  // EvaluationPlayer: { ScoreBar: true, Transcript: true, ConversationRecording: true },

  CommonColor: {
    HIGH: '#04bf0d',
    MEDIUM: '#f1ad0f',
    LOW: '#f10f0f',
    BGHIGH: '#FFF1F0',
    BGLOW: '#F9F8DD'
  },
  CommonHighLowColor: {
    HIGH: '#04bf0d',
    LOW: '#f10f0f'
  },
  PercentageRange: {
    HIGH: 100,
    MEDIUM: 60,
    LOW: 30
  },
  CommonTextColor: {
    HIGH: '#064E3B',
    LOW: '#7F1D1D',
    MEDIUM: '#7c2d12'
  },
  TextPercentageRange: {
    HIGH: 100,
    MEDIUM: 60,
    LOW: 30
  },
  AnalyticsBasicCard: {
    Title: true,
    Score: true,
    Badge: true
  },
  // webConfigAllData: null,
  // PageConfig: null, // dynamic state
  PageConfig: {
    dashboard: { status: true },
    instagram: { status: false },
    facebook: { status: false },
    inbox_management: { status: true }
  },
  ScoreCardConfig: null,
  DrawerConfig: null,
  CommonColorConfig: null,
  PercentageRangeConfig: null,
  DashboardConfig: null,
  AnalyticsConfig: null,
  EvaluationConfig: null,
  EvaluationPlayerConfig: null,
  InspectConfig: null,
  InspectPlayerConfig: null,
  SettingsConfig: null,
  FeedBackConfig: null,
  setSelectedLanguage: null,
  setSelectedDirection: null,
  subscription_based_features: null,
  ui: {
    loading: false
  }
};

const getpagedata = createAsyncThunk('Web/getAllData', async () => {});

const getWebConfig = createAsyncThunk('WebConfig/getWebConfig', async (_, { rejectWithValue }) => {
  try {
    const res = await webConfigAPI.getWebConfig();
    return res?.data?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getSubscriptionBasedFeatures = createAsyncThunk('WebConfig/getSubscriptionBasedFeatures', async (_, { rejectWithValue }) => {
  try {
    const res = await webConfigAPI.getSubscriptionBasedFeatures();
    return res?.data?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const WebConfigSlice = createSlice({
  name: 'WebConfig',
  initialState: initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setLanguage: (state, action) => {
      // localStorage.setItem('setSelectedLanguage', action.payload);
      state.setSelectedLanguage = action.payload;
    },
    setDirection: (state, action) => {
      state.setSelectedDirection = action.payload;
    }
  },
  extraReducers: {
    [getpagedata.pending]: (state) => {
      state.ui.loading = true;
    },
    [getpagedata.fulfilled]: (state, action) => {
      state.ui.loading = false;
      state.data = action.payload;
    },
    [getpagedata.rejected]: (state) => {
      state.ui.loading = false;
    },
    [getWebConfig.pending]: () => {
      // state.ui.loading = true;
    },
    [getWebConfig.fulfilled]: (state, action) => {
      state.ScoreCardConfig = action.payload.scorecard;
      // state.PageConfig = action.payload.Pages;// not dynamic....drawervalues are taken from here
      state.DrawerConfig = action.payload.Drawer;
      state.CommonColorConfig = action.payload.CommonColor;
      state.PercentageRangeConfig = action.payload.PercentageRange;
      state.CommonTextColorConfig = action.payload.CommonTextColor;
      state.TextPercentageRangeConfig = action.payload.TextPercentageRange;
      state.DashboardConfig = action.payload.dashboard;
      state.AnalyticsConfig = action.payload.analytics;
      state.EvaluationConfig = action.payload.evaluation;
      state.EvaluationPlayerConfig = action.payload.evaluation_player;
      state.InspectConfig = action.payload.inspect;
      state.InspectPlayerConfig = action.payload.inspect_player;
      state.SettingsConfig = action.payload.settings;
      state.FeedBackConfig = action.payload.feedback;
    },
    [getWebConfig.rejected]: (state, action) => {
      // state.ui.loading = false;
      console.log('webaction', action.paylod);
    },
    [getSubscriptionBasedFeatures.pending]: (state) => {
      state.ui.loading = true;
    },
    [getSubscriptionBasedFeatures.fulfilled]: (state, action) => {
      state.ui.loading = false;
      state.subscription_based_features = action.payload;
    },
    [getSubscriptionBasedFeatures.rejected]: (state) => {
      state.ui.loading = false;
    }
  }
});

const { resetState, setLanguage, setDirection } = WebConfigSlice.actions;
export default WebConfigSlice.reducer;
export { resetState, getWebConfig, setLanguage, setDirection, getSubscriptionBasedFeatures };
