import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project import
import Dot from 'components/@extended/Dot';

import { MenuOrientation, ThemeMode } from 'config';
import useConfig from 'hooks/useConfig';
// import { dispatch, useSelector } from 'store';
import { activeItem, openDrawer } from 'store/reducers/menu';
// import ConditionalComponent from 'components/common/ConditionalComponent';
import ConditionalComponent from 'components/common/ConditionalComponent';
import useDrawerConfig from 'hooks/WebConfig/useDrawerConfig';
import useAuthStore from 'hooks/useAuth';
import { useNavigate } from 'react-router';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //
const handlechange = (value, item) => {
  for (const key in value) {
    if (key === item && value[key].status === true) {
      return true;
    }
  }
  return false;
};
const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { menuOrientation } = useConfig();
  const { drawerOpen, openItem } = useSelector((state) => state.menu);

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = { component: forwardRef((props, ref) => <Link {...props} to={item.url} target={itemTarget} ref={ref} />) };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const Icon = item.icon;
  const itemIcon = item.icon ? <Icon size={20} variant={isSelected ? 'Bold' : 'Linear'} color={theme.palette.secondary.main} /> : false;

  // const { pathname } = useLocation();
  const pathname = document.location.pathname;

  // active menu item on page load
  useEffect(() => {
    if (pathname && pathname.includes('product-details')) {
      if (item.url && item.url.includes('product-details')) {
        dispatch(activeItem({ openItem: [item.id] }));
      }
    }

    if (pathname && pathname.includes('kanban')) {
      if (item.url && item.url.includes('kanban')) {
        dispatch(activeItem({ openItem: [item.id] }));
      }
    }

    if (pathname.includes(item.url)) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  // console.log('pathname here', pathname, item);

  const textColor = theme.palette.mode === ThemeMode.DARK ? 'grey.400' : 'grey.800';
  const iconSelectedColor = theme.palette.mode === ThemeMode.DARK && drawerOpen ? 'text.primary' : 'primary.main';
  const { Drawer } = useDrawerConfig();
  const { logoutAction } = useAuthStore();

  const handleLogout = async () => {
    try {
      logoutAction();
      navigate(`/login`, {
        state: {
          from: ''
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      {menuOrientation === MenuOrientation.VERTICAL || downLG ? (
        <>
          <ConditionalComponent show={handlechange(Drawer, item?.id)}>
            <ListItemButton
              {...listItemProps}
              disabled={item.disabled}
              selected={isSelected}
              sx={{
                // mt: item.id === 'help' ? 27 : 0, //separated group-help from group-modules
                zIndex: 1201,
                pl: drawerOpen ? `${level * 28}px` : 1.5,
                py: !drawerOpen && level === 1 ? 1 : 1,
                ...(drawerOpen && {
                  '&:hover': {
                    bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter'
                  },
                  '&.Mui-selected': {
                    bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter',
                    borderRight: `2px solid ${theme.palette.primary.main}`,
                    color: iconSelectedColor,
                    '&:hover': {
                      color: iconSelectedColor,
                      bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter'
                    }
                  }
                }),
                ...(!drawerOpen && {
                  '&:hover': {
                    bgcolor: 'transparent'
                  },
                  '&.Mui-selected': {
                    '&:hover': {
                      bgcolor: 'transparent'
                    },
                    bgcolor: 'transparent'
                  }
                })
              }}
              {...(downLG && {
                onClick: () => dispatch(openDrawer(false))
              })}
              onClick={() => {
                if (item.id === 'logout') {
                  handleLogout(); // Call handleLogout when item.id is 'logout'
                }
              }}
            >
              {itemIcon && (
                <ListItemIcon
                  // onClick={handleClicks}
                  sx={{
                    minWidth: 34,
                    color: isSelected ? iconSelectedColor : textColor,
                    ...(!drawerOpen && {
                      borderRadius: 1.5,
                      width: 36,
                      height: 36,
                      alignItems: 'center',
                      justifyContent: 'center',
                      '&:hover': {
                        bgcolor: theme.palette.mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.lighter'
                      }
                    }),
                    ...(!drawerOpen &&
                      isSelected && {
                        bgcolor: theme.palette.mode === ThemeMode.DARK ? 'primary.900' : 'primary.lighter',
                        '&:hover': {
                          bgcolor: theme.palette.mode === ThemeMode.DARK ? 'primary.darker' : 'primary.lighter'
                        }
                      })
                  }}
                >
                  {itemIcon}
                </ListItemIcon>
              )}
              {(drawerOpen || (!drawerOpen && level !== 1)) && (
                <ListItemText
                  primary={
                    <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
                      {item.title}
                    </Typography>
                  }
                />
              )}
              {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                <Chip
                  color={item.chip.color}
                  variant={item.chip.variant}
                  size={item.chip.size}
                  label={item.chip.label}
                  avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
              )}
            </ListItemButton>
          </ConditionalComponent>
        </>
      ) : (
        <ListItemButton
          {...listItemProps}
          disabled={item.disabled}
          selected={isSelected}
          sx={{
            zIndex: 1201,
            ...(drawerOpen && {
              '&:hover': {
                bgcolor: 'transparent'
              },
              '&.Mui-selected': {
                bgcolor: 'transparent',
                color: iconSelectedColor,
                '&:hover': {
                  color: iconSelectedColor,
                  bgcolor: 'transparent'
                }
              }
            }),
            ...(!drawerOpen && {
              '&:hover': {
                bgcolor: 'transparent'
              },
              '&.Mui-selected': {
                '&:hover': {
                  bgcolor: 'transparent'
                },
                bgcolor: 'transparent'
              }
            })
          }}
        >
          {itemIcon && (
            <ListItemIcon
              sx={{
                minWidth: 36,
                ...(!drawerOpen && {
                  borderRadius: 1.5,
                  width: 36,
                  height: 36,
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    bgcolor: 'transparent'
                  }
                }),
                ...(!drawerOpen &&
                  isSelected && {
                    bgcolor: 'transparent',
                    '&:hover': {
                      bgcolor: 'transparent'
                    }
                  })
              }}
            >
              {itemIcon}
            </ListItemIcon>
          )}

          {!itemIcon && (
            <ListItemIcon
              sx={{
                color: isSelected ? 'primary.main' : 'secondary.main',
                ...(!drawerOpen && {
                  borderRadius: 1.5,
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    bgcolor: 'transparent'
                  }
                }),
                ...(!drawerOpen &&
                  isSelected && {
                    bgcolor: 'transparent',
                    '&:hover': {
                      bgcolor: 'transparent'
                    }
                  })
              }}
            >
              <Dot size={4} color={isSelected ? 'primary' : 'secondary'} />
            </ListItemIcon>
          )}
          <ListItemText
            primary={
              <Typography variant="h6" color="inherit">
                {item.title}
              </Typography>
            }
          />
          {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </ListItemButton>
      )}
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
};

export default NavItem;
