import axios from 'api';
const config = {
  headers: {
    // withCredentials: true
  }
};
export const getWebConfig = async () => {
  return axios.get(`api/frontend_api/web_config`, config);
};

export const getSubscriptionBasedFeatures = () => {
  return axios.get(`api/frontend_api/subscription_based_features`);
};
