// import { extractData } from 'utils/Sidebar';
const apiMiddleware = (store) => (next) => (action) => {
  console.log('store', store);
  switch (action.type) {
    // case 'persist/REHYDRATE':
    //   if (action?.payload?.rangeDate)
    //     action.payload.rangeDate = [new Date(action?.payload?.rangeDate[0]), new Date(action?.payload?.rangeDate[1])];
    //   next(action);
    //   break;

    // case 'contacts/setAllFilterFromParent':
    //   action.payload = extractData(store.getState().list, ['rangeDate']);
    //   next(action);
    //   break;
    // case 'recordings/setAllFilterFromParent':
    //   action.payload = extractData(store.getState().campaigns, ['rangeDate']);
    //   next(action);
    //   break;
    // case 'agent/setChatdata':
    //   // action.payload = store.getState().filter;

    //   next(action);

    //   store.dispatch(chatAgent(action.payload.paramsobj));

    //   break;

    // case 'recordings/handleCallTypeFilterChange':
    //   next(action);
    //   getAgentsList(store, store.getState().recordings.selected);
    //   break;

    // case 'recordings/handleCallTypeAllFilterChange':
    //   next(action);
    //   getAgentsList(store, store.getState().recordings.selected);
    //   break;

    // case 'recordings/handleAgentFilterChange':
    //   next(action);
    //   getCampaignsList(store, store.getState().recordings.selected);
    //   break;

    // case 'recordings/handleAgentAllFilterChange':
    //   next(action);
    //   getCampaignsList(store, store.getState().recordings.selected);
    //   break;

    // case 'reporting/handleCallTypeFilterChange':
    //   next(action);
    //   getAgentsListReporting(store, store.getState().reporting.selected);
    //   break;

    // case 'reporting/handleCallTypeAllFilterChange':
    //   next(action);
    //   getAgentsListReporting(store, store.getState().reporting.selected);
    //   break;

    // case 'reporting/handleAgentFilterChange':
    //   next(action);
    //   getCampaignsListReporting(store, store.getState().reporting.selected);
    //   break;

    // case 'reporting/handleAgentAllFilterChange':
    //   next(action);
    //   getCampaignsListReporting(store, store.getState().reporting.selected);
    //   break;

    // case 'evaluationfilter/setAllfilterFromParent':
    //   action.payload = extractData(store.getState().analyticsfilter, [
    //     'extra_filter',
    //     'features_filter',
    //     'LOB',
    //     'LOBTeam',
    //     'LOBTeamAgent',
    //     'rangeDate',
    //     'fields',
    //     'selected'
    //   ]);
    //   // console.log('"evaluationfilter/setAllfilterFromParent"', action.payload);
    //   next(action);
    //   break;
    case 'auth/logout':
      next(action);
      break;
    default:
      next(action);
      break;
  }
};
export default apiMiddleware;
