import axios from 'axios';
import { setRefreshTokenRes, logout } from 'store/auth';
import { refreshTokenAPI } from 'api/Auth';
import { store } from 'store/index';
// import { decryptData } from 'utils/common';

const getBaseURL = () => {
  const currentLocation = window.location;
  const baseURL = `${currentLocation.protocol}//${currentLocation.hostname}`;
  return baseURL;
};

// const baseURL = process.env.NODE_ENV === 'production' ? getBaseURL() : 'https://demo.qms.servicepack.ai/';
const baseURL = process.env.NODE_ENV === 'production' ? getBaseURL() : 'https://chat.chiselsolutions.ai/';
// const baseURL = process.env.REACT_APP_API_URL || 'https://qp-dev.servicepack.ai:8000/';
const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    // 'Authorization': "bearer " + localStorage.getItem('access_token'),
    'Content-Type': 'application/json',
    accept: 'application/json'
  }
});
axiosInstance.interceptors.response.use(
  async (response) => {
    const originalRequest = response.config;

    if (response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = store?.getState()?.auth?.tokens.refresh;
        console.log('refreshToken', refreshToken);
        const accessToken = await refreshTokenAPI(refreshToken);
        console.log('accessToken', accessToken);
        store.dispatch(setRefreshTokenRes({ access: accessToken.data.data.access }));
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken.data.data.access}`;
        originalRequest.headers['Authorization'] = `Bearer ${accessToken.data.data.access}`;
        if (accessToken.data.error) {
          // Handle refresh token expiration here.
          store.dispatch(logout());
        }

        return axiosInstance(originalRequest);
      } catch (_error) {
        store.dispatch(logout());
        console.log('API Client error => catch', _error);
      }
    }
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      // console.log('API Client error', error);
      originalRequest._retry = true;
      try {
        const refreshToken = store?.getState()?.auth?.tokens.refresh;
        const accessToken = await refreshTokenAPI(refreshToken);
        store.dispatch(setRefreshTokenRes({ access: accessToken.data.data.access }));
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken.data.data.access}`;
        originalRequest.headers['Authorization'] = `Bearer ${accessToken.data.data.access}`;
        if (accessToken.data.error) {
          // Handle refresh token expiration here.
          store.dispatch(logout());
        }
        return axiosInstance(originalRequest);
      } catch (_error) {
        store.dispatch(logout());
        console.log('API Client error => catch', _error);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

// const baseURL = process.env.REACT_APP_API_URL || 'https://qp-dev.servicepack.ai:8000/';
export const axiosInstanceForRefreshToken = axios.create({
  baseURL: baseURL,
  headers: {
    // 'Authorization': "bearer " + localStorage.getItem('access_token'),
    'Content-Type': 'application/json',
    accept: 'application/json'
  }
});
