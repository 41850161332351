import { useDispatch, useSelector } from 'react-redux';
import { login, logout, reCaptchaVarify, register, resetPassword, sendResetLink } from 'store/auth';

function useAuthStore() {
  const isLoggedIn = useSelector((state) => state.auth.isAuth);
  const user = useSelector((state) => state.auth.data);
  const AccessToken = useSelector((state) => state.auth?.tokens?.access);
  const Loginshow = useSelector((state) => state.auth?.show);
  const dispatch = useDispatch();
  const loginAction = (params) => dispatch(login(params));
  const logoutAction = () => dispatch(logout());
  const reCaptchaVarifyAction = (params) => dispatch(reCaptchaVarify(params));
  const registerAction = (params) => dispatch(register(params));
  const sendResetLinkAction = (params) => dispatch(sendResetLink(params));
  const resetPasswordAction = (params) => dispatch(resetPassword(params));
  return {
    // state of Analytics
    isLoggedIn,
    user,
    AccessToken,
    Loginshow,
    // action of Analytics
    loginAction,
    logoutAction,
    reCaptchaVarifyAction,
    registerAction,
    sendResetLinkAction,
    resetPasswordAction
  };
}
export default useAuthStore;
