import { makeStyles } from '@mui/styles';
import { ThemeMode } from 'config';
export const useStyles = makeStyles((theme) => ({
  inputContainer: {
    // minWidth: 150,
    paddingTop: theme.spacing(0), // Set padding using spacing values
    paddingBottom: theme.spacing(0), // Set padding using spacing values
    background: `${theme.palette.mode === ThemeMode.DARK ? theme.palette.grey[100] : theme.palette.grey[0]} !important`,
    borderRadius: theme.spacing(1),
    border: '1px solid',
    borderColor: theme.palette.mode === ThemeMode.DARK ? theme.palette.divider : theme.palette.grey.A800
  },
  formGroup: {
    padding: theme.spacing(0.5) // Set padding using spacing values
  },
  listSubheader: {
    lineHeight: 1,
    padding: theme.spacing(0.5) // Set padding using spacing values
  },
  textField: {
    padding: theme.spacing(0.5),
    '& .MuiInputBase-root': {
      padding: theme.spacing(0.5),
      border: 'none', // Remove the border
      '&:hover': {
        border: 'none' // Remove the border on hover
      },
      '&.Mui-focused': {
        border: 'none' // Remove the border when focused
      }
    }
  }
}));
