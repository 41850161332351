import PropTypes from 'prop-types';

// material-ui
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// project import
// import getColors from 'utils/getColors';

const Dot = ({ color, size, sx }) => {
  // const theme = useTheme();
  // const colors = getColors(theme, color || 'primary');
  // const { main } = colors;

  return (
    <Box
      component="span"
      sx={{
        width: size || 8,
        height: size || 8,
        borderRadius: '50%',
        // bgcolor: variant === 'outlined' ? '' : main,
        // ...(variant === 'outlined' && {
        //   border: `1px solid ${main}`
        // }),
        bgcolor: color,
        ...sx
      }}
    />
  );
};

Dot.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  variant: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default Dot;

// dynamic code

// import PropTypes from 'prop-types';
// import { Box } from '@mui/material';
// import useWebConfig from 'hooks/useWebConfig';
// import getPercentageRangeColor from 'utils/getPercentageRangeColor';

// const Dot = (props) => {
//   const { type, value } = props;
//   const { CommonColor, PercentageRange } = useWebConfig();
//   console.log('getPercentageRangeColor123', value);
//   return (
//     <>
//       {type === 'number' && (
//         <Box
//           component="span"
//           display="inline-block"
//           bgcolor="lightgrey"
//           p={0.5}
//           sx={{
//             width: 8,
//             height: 8,
//             borderRadius: '50%',
//             bgcolor: getPercentageRangeColor(value, CommonColor, PercentageRange),
//             mr: 0.5
//           }}
//         />
//       )}
//     </>
//   );
// };

// Dot.propTypes = {
//   value: PropTypes.number,
//   size: PropTypes.number,
//   variant: PropTypes.string,
//   sx: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
//   type: PropTypes.string
// };

// export default Dot;
