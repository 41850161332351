import { useDispatch, useSelector } from 'react-redux';
import { setDirection, setLanguage } from 'store/Web-Config';

function useDrawerConfig() {
  const dispatch = useDispatch();

  const Drawer = useSelector((state) => state?.WebConfig?.PageConfig);
  const LanguageBox = useSelector((state) => state?.WebConfig?.DrawerConfig?.language?.status);
  const LanguageTitle = useSelector((state) => state?.WebConfig?.DrawerConfig?.language?.name);
  const LanguageData = useSelector((state) => state?.WebConfig?.DrawerConfig?.language?.value);
  const SelectedLanguage = useSelector((state) => state?.WebConfig?.setSelectedLanguage);
  const DirectionBox = useSelector((state) => state?.WebConfig?.DrawerConfig?.direction?.status);
  const DirectionTitle = useSelector((state) => state?.WebConfig?.DrawerConfig?.direction?.name);
  const DirectionData = useSelector((state) => state?.WebConfig?.DrawerConfig?.direction?.value);
  const SelectedDirection = useSelector((state) => state.WebConfig?.setSelectedDirection);

  const setLanguageAction = (params) => dispatch(setLanguage(params));
  const setDirectionAction = (params) => dispatch(setDirection(params));

  return {
    Drawer,
    LanguageBox,
    LanguageTitle,
    LanguageData,
    SelectedLanguage,
    DirectionBox,
    DirectionData,
    DirectionTitle,
    SelectedDirection,
    setLanguageAction,
    setDirectionAction
  };
}

export default useDrawerConfig;
