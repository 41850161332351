import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, ClickAwayListener, Paper, Popper, Stack } from '@mui/material';

// project import
// import Search from './Search';
// import Profile from './Profile';
// import Localization from './Localization';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import { ThemeMode } from 'config';
import MainCard from 'components/MainCard';
import Language from './Language';
import useDrawerConfig from 'hooks/WebConfig/useDrawerConfig';
import ConditionalComponent from 'components/common/ConditionalComponent';
import Direction from './Direction';

// assets
import { MoreOutlined } from '@ant-design/icons';

// ==============================|| HEADER CONTENT - MOBILE ||============================== //

const MoreOptions = () => {
  const theme = useTheme();
  const { LanguageBox, DirectionBox } = useDrawerConfig();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = (event) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'grey.50' : 'grey.100';
  const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.50';

  return (
    <>
      <ConditionalComponent show={LanguageBox || DirectionBox}>
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
          <IconButton
            sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
            aria-label="open more menu"
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="secondary"
            variant="light"
          >
            <MoreOutlined />
          </IconButton>
        </Box>
      </ConditionalComponent>

      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position="top-right" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                minWidth: 180,
                maxWidth: 290,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 250
                }
              }}
            >
              <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClose}>
                <MainCard elevation={0} border={false} content={true}>
                  <Stack spacing={2}>
                    <ConditionalComponent show={DirectionBox}>
                      <Direction />
                    </ConditionalComponent>
                    <ConditionalComponent show={LanguageBox}>
                      <Language />
                    </ConditionalComponent>
                  </Stack>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      {/* <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ width: '100%' }}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper sx={{ boxShadow: theme.customShadows.z1 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <AppBar color="inherit">
                  <Toolbar>
                    <Search />
                    <Localization />
                    <Profile />
                  </Toolbar>
                </AppBar>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper> */}
    </>
  );
};

export default MoreOptions;
