import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionBasedFeatures, getWebConfig, resetState } from 'store/Web-Config';

function useWebConfig() {
  const dispatch = useDispatch();
  const scorecard = useSelector((state) => state?.WebConfig?.Scorecard);
  const Search = useSelector((state) => state?.WebConfig?.ScoreCardConfig?.search?.status);
  const CommonColor = useSelector((state) => state?.WebConfig?.CommonColor);
  const CommonTextColor = useSelector((state) => state?.WebConfig?.CommonTextColorConfig);

  //subscription based features
  const subscription_based_features = useSelector((state) => state?.WebConfig?.subscription_based_features);
  const agent_limitation = useSelector((state) => state?.WebConfig?.subscription_based_features?.no_of_agents);

  const TextPercentageRange = useSelector((state) => state?.WebConfig?.TextPercentageRangeConfig);
  const PercentageRange = useSelector((state) => state?.WebConfig?.PercentageRangeConfig);
  const Pages = useSelector((state) => state?.WebConfig?.Pages);
  const DownloadReport = useSelector((state) => state?.WebConfig?.ScoreCardConfig?.download_reports?.status);
  const SelectDate = useSelector((state) => state?.WebConfig?.ScoreCardConfig?.select_date?.status);
  const LobBox = useSelector((state) => state?.WebConfig?.ScoreCardConfig?.select_lob?.status);
  const AgentBox = useSelector((state) => state?.WebConfig?.ScoreCardConfig?.select_agent?.status);
  const TeamBox = useSelector((state) => state?.WebConfig?.ScoreCardConfig?.select_team?.status);
  const ClearAll = useSelector((state) => state?.WebConfig?.ScoreCardConfig?.clearall?.status);
  const AddFeature = useSelector((state) => state?.WebConfig?.ScoreCardConfig?.addcolumn?.status);
  const AdvanceFilter = useSelector((state) => state?.WebConfig?.ScoreCardConfig?.advancefilter?.status);
  const CqScoreBox = useSelector((state) => state?.WebConfig?.AnalyticsConfig?.cq_score?.status);

  const getWebConfigAction = () => dispatch(getWebConfig());
  const getSubscriptionBasedFeaturesAction = () => dispatch(getSubscriptionBasedFeatures());
  const resetWebConfig = () => dispatch(resetState());

  return {
    scorecard,
    subscription_based_features,
    agent_limitation,
    CommonColor,
    Search,
    PercentageRange,
    Pages,
    DownloadReport,
    SelectDate,
    LobBox,
    AgentBox,
    TeamBox,
    ClearAll,
    AddFeature,
    AdvanceFilter,
    CqScoreBox,
    CommonTextColor,
    TextPercentageRange,
    getWebConfigAction,
    resetWebConfig,
    getSubscriptionBasedFeaturesAction
  };
}

export default useWebConfig;
