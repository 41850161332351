import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Stack, Typography } from '@mui/material';

// project import
// import LogoMain from './LogoMain';
// import LogoIcon from './LogoIcon';
import { APP_DEFAULT_PATH } from 'config';
// import LogoServicepack from 'assets/images/common/ServicePackSmallLogo.svg';
// import MainServicepackLogo from 'assets/images/common/ServicePackFullLogo.svg';
// import ServicepackWhiteSmallLogo from 'assets/images/common/ServicepackWhiteSmallLogo.svg';
// import ServicepackWhiteFullLogo from 'assets/images/common/ServicepackWhiteFullLogo.svg';

import { useTheme } from '@mui/styles';
// import ExperienseFull from './ExpFull';
// import ExperienseShort from './ExpShort';
import ChiselLogo from 'assets/images/Chisel Ai.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ /* isIcon, */ sx, to }) => {
  const theme = useTheme();
  return (
    <ButtonBase disableRipple component={Link} to={!to ? APP_DEFAULT_PATH : to} sx={{ pt: theme.spacing(2), pl: 1.5, ...sx }}>
      {/* {isIcon ? <ExperienseShort /> : <ExperienseFull />} */}
      <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
        <img src={ChiselLogo} alt="Chisel.ai" style={{ width: '50px', height: '50px' }} />
        <Typography sx={{ fontSize: '28px', fontWeight: '800' }}>Chisel.ai</Typography>
      </Stack>
    </ButtonBase>
  );
};

LogoSection.propTypes = {
  reverse: PropTypes.bool,
  isIcon: PropTypes.bool,
  sx: PropTypes.object,
  to: PropTypes.string
};

export default LogoSection;
