import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as InboxApi from 'api/Inbox';
import { openSnackbar } from 'store/reducers/snackbar';

const initialState = {
  channels: null,
  inboxes: null,
  selectedInbox: null,
  selectedChannel: null,
  selectedPsids: null,
  psids: null,
  chats: null,
  tokens: {
    fbPageToken: [],
    whatsAppTokens: {},
    instagramTokens: {}
  },
  status: '',
  error: '',
  ui: {
    loading: false,
    psidLoading: false,
    chatLoading: false,
    msg: ''
  }
};

const getAllChannels = createAsyncThunk('inbox/getAllChannels', async () => {
  const res = await InboxApi.getAllChannels();
  return res.data;
});

const getFbPageToken = createAsyncThunk('inbox/getFbPageToken', async ({ params }) => {
  const res = await InboxApi.getFbPageTokens(params);
  console.log('fbpagetoken', res.data);
  return res.data;
});

const createFacebookInbox = createAsyncThunk('inbox/createFacebookInbox', async (params, { dispatch, rejectWithValue }) => {
  try {
    const res = await InboxApi.createFacebookInbox(params);
    if (res.success) {
      return { success: true, data: res.data };
    } else {
      dispatch(
        openSnackbar({
          open: true,
          title: 'Error in inbox.',
          message: res?.message?.response?.data?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return { success: false, error: res.data };
    }
  } catch (error) {
    dispatch(
      openSnackbar({
        open: true,
        message: error.response.data?.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return rejectWithValue(err);
  }
});

const getAllInboxes = createAsyncThunk('inbox/getAllInboxes', async () => {
  const res = await InboxApi.getAllInboxes();
  return res.data;
});

const getAllPsids = createAsyncThunk('inbox/getAllPsids', async ({ params, signal }) => {
  const res = await InboxApi.getAllPsids(params, signal);
  return res.data;
});

const getChats = createAsyncThunk('inbox/getChats', async ({ params, signal }) => {
  const res = await InboxApi.getChats(params, signal);
  return res.data;
});

//whatsapp inboxes
const createWhatsAppInbox = createAsyncThunk('inbox/createWhatsAppInbox', async (params, { dispatch, rejectWithValue }) => {
  try {
    const res = await InboxApi.createWhatsAppInbox(params);
    if (res.success) {
      return { success: true, data: res.data };
    } else {
      dispatch(
        openSnackbar({
          open: true,
          title: 'Error in inbox.',
          message: res?.message?.response?.data?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return { success: false, error: res.data };
    }
  } catch (error) {
    dispatch(
      openSnackbar({
        open: true,
        message: error.response.data?.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return rejectWithValue(err);
  }
});

//instagram inboxes
const createInstagramInbox = createAsyncThunk('inbox/createInstagramInbox', async (params, { dispatch, rejectWithValue }) => {
  try {
    const res = await InboxApi.createInstagramInbox(params);
    if (res.success) {
      return { success: true, data: res.data };
    } else {
      dispatch(
        openSnackbar({
          open: true,
          title: 'Error in inbox.',
          message: res?.message?.response?.data?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return { success: false, error: res.data };
    }
  } catch (error) {
    dispatch(
      openSnackbar({
        open: true,
        message: error.response.data?.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return rejectWithValue(err);
  }
});

const inboxSlice = createSlice({
  name: 'inbox',
  initialState: initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setSelectedChannel: (state, action) => {
      state.selectedChannel = action.payload;
    },
    setSelectedInbox: (state, action) => {
      state.selectedInbox = action.payload;
    },
    setSelectedPsids: (state, action) => {
      state.selectedPsids = action.payload;
    },
    setWhatsAppTokens: (state, action) => {
      state.tokens.whatsAppTokens = action.payload;
    },
    setInstagramToken: (state, action) => {
      state.tokens.instagramTokens = action.payload;
    }
  },
  extraReducers: {
    [getAllChannels.pending]: (state) => {
      state.ui.loading = true;
      state.status = 'init';
    },
    [getAllChannels.fulfilled]: (state, action) => {
      state.ui.loading = false;
      state.channels = action.payload;
      state.status = 'idle';
    },
    [getAllChannels.rejected]: (state, action) => {
      state.status = 'error';
      state.error = action.error.message;
      state.ui.loading = false;
    },
    [getFbPageToken.pending]: (state) => {
      state.ui.loading = true;
      state.status = 'init';
    },
    [getFbPageToken.fulfilled]: (state, action) => {
      state.ui.loading = false;
      state.status = 'idle';
      state.tokens.fbPageToken = action.payload;
    },
    [getFbPageToken.rejected]: (state, action) => {
      state.status = 'error';
      state.error = action.error.message;
      state.ui.loading = false;
    },
    [createFacebookInbox.pending]: (state) => {
      state.ui.loading = true;
      state.status = 'init';
    },
    [createFacebookInbox.fulfilled]: (state) => {
      state.ui.loading = false;
      state.status = 'idle';
    },
    [createFacebookInbox.rejected]: (state, action) => {
      state.ui.loading = false;
      state.status = 'error';
      state.error = action.error.message;
    },
    [getAllInboxes.pending]: (state) => {
      state.ui.loading = true;
      state.status = 'init';
    },
    [getAllInboxes.fulfilled]: (state, action) => {
      state.ui.loading = false;
      state.status = 'idle';
      state.inboxes = action.payload;
    },
    [getAllInboxes.rejected]: (state, action) => {
      state.ui.loading = false;
      state.status = 'error';
      state.error = action.error.message;
    },
    [getAllPsids.pending]: (state) => {
      state.ui.psidLoading = true;
      state.status = 'init';
    },
    [getAllPsids.fulfilled]: (state, action) => {
      state.ui.psidLoading = false;
      state.status = 'idle';
      state.psids = action.payload;
    },
    [getAllPsids.rejected]: (state, action) => {
      state.ui.psidLoading = false;
      state.status = 'error';
      state.error = action.error.message;
    },
    [getChats.pending]: (state) => {
      state.ui.chatLoading = true;
      state.status = 'init';
    },
    [getChats.fulfilled]: (state, action) => {
      state.ui.chatLoading = false;
      state.status = 'idle';
      state.chats = action.payload;
    },
    [getChats.rejected]: (state, action) => {
      state.ui.chatLoading = false;
      state.status = 'error';
      state.error = action.error.message;
    },
    [createWhatsAppInbox.pending]: (state) => {
      state.ui.loading = true;
      state.status = 'init';
    },
    [createWhatsAppInbox.fulfilled]: (state) => {
      state.ui.loading = false;
      state.status = 'idle';
    },
    [createWhatsAppInbox.rejected]: (state, action) => {
      state.ui.loading = false;
      state.status = 'error';
      state.error = action.error.message;
    },
    [createInstagramInbox.pending]: (state) => {
      state.ui.loading = true;
      state.status = 'init';
    },
    [createInstagramInbox.fulfilled]: (state) => {
      state.ui.loading = false;
      state.status = 'idle';
    },
    [createInstagramInbox.rejected]: (state, action) => {
      state.ui.loading = false;
      state.status = 'error';
      state.error = action.error.message;
    }
  }
});

const { resetState, setSelectedChannel, setSelectedInbox, setSelectedPsids, setWhatsAppTokens, setInstagramToken } = inboxSlice.actions;

export default inboxSlice.reducer;

export {
  resetState,
  setSelectedChannel,
  setSelectedInbox,
  setSelectedPsids,
  getAllChannels,
  getFbPageToken,
  createFacebookInbox,
  getAllInboxes,
  getAllPsids,
  getChats,
  setWhatsAppTokens,
  setInstagramToken,
  createWhatsAppInbox,
  createInstagramInbox
};
