import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as authAPI from 'api/Auth/index';
import { openSnackbar } from 'store/reducers/snackbar';
// import { openSnackbarFunction } from 'utils/common';
const initialState = {
  data: null,
  isAuth: false,
  show: false,
  reCaptchaVerify: null,
  tokens: {
    access: null,
    refresh: null
  },
  status: 'init', // "loggingIn" | "loggingOut" | "sessionExpired"
  error: null,
  admin: false,
  ui: {
    login: { loading: false },
    msg: ''
  }
};
const getAuthState = (state) => state.auth;

const login = createAsyncThunk('auth/login', async (params, { dispatch, rejectWithValue }) => {
  try {
    const res = await authAPI.login(params);
    console.log('res from login api', res);
    return res.data;
  } catch (error) {
    console.error('auth/login', error.response.data?.message);
    dispatch(
      openSnackbar({
        open: true,
        title: 'Error in login',
        message: error.response.data?.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return rejectWithValue(error);
  }
});

const register = createAsyncThunk('auth/register', async (params, { dispatch, rejectWithValue }) => {
  try {
    const res = await authAPI.register(params);
    if (res.success) {
      dispatch(
        openSnackbar({
          open: true,
          message: res?.data?.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
      return { success: true, data: res.data };
    } else {
      dispatch(
        openSnackbar({
          open: true,
          title: 'Error in login',
          message: 'Registration failed. Please try again.',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return { success: false, error: res };
    }
  } catch (error) {
    dispatch(
      openSnackbar({
        open: true,
        title: 'Error in login',
        message: 'Registration failed. Please try again.',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return rejectWithValue(error);
  }
});

const sendResetLink = createAsyncThunk('auth/sendResetLink', async (params, { dispatch, rejectWithValue }) => {
  try {
    const res = await authAPI.sendResetLink(params);
    if (res.success) {
      return { success: true, data: res.data };
    } else {
      dispatch(
        openSnackbar({
          open: true,
          title: 'Error in forgot password.',
          message: res?.message?.response?.data?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return { success: false, error: res.data };
    }
  } catch (error) {
    dispatch(
      openSnackbar({
        open: true,
        title: 'Error in Sending reset link',
        message: error.response.data?.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return rejectWithValue(error);
  }
});

const resetPassword = createAsyncThunk('auth/resetPassword', async (params, { dispatch, rejectWithValue }) => {
  try {
    const res = await authAPI.resetPassword(params);
    // openSnackbarFunction(res);
    if (res.success) {
      return { success: true, data: res.data };
    } else {
      dispatch(
        openSnackbar({
          open: true,
          title: 'Error in forgot password.',
          message: res?.message?.response?.data?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return { success: false, error: res.data };
    }
  } catch (error) {
    dispatch(
      openSnackbar({
        open: true,
        title: 'Error in resetting password.',
        message: 'Something went wrong!',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return rejectWithValue(error);
  }
});

const reCaptchaVarify = createAsyncThunk('auth/reCaptchaVarify', async (params, { dispatch, rejectWithValue }) => {
  try {
    const res = await authAPI.reCaptchaVarify(params);
    return res.data;
  } catch (error) {
    dispatch(
      openSnackbar({
        open: true,
        title: 'Error in captcha verification',
        message: error.response.data?.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return rejectWithValue(error);
  }
});

// const resetPassword = createAsyncThunk('auth/resetPassword', async ({ user }) => {
//   if (user === null) return;
// });

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,

  reducers: {
    persistLogin: (state) => {
      state.status = 'idle';
    },
    userregister: (state) => {
      state.isAuth = false;
      state.tokens = initialState.tokens;
      state.status = 'unregistered';
    },
    logout: () => {
      return {
        ...initialState,
        status: 'loggingOut'
      };
    },
    // sessionExpired: (state) => {
    //   // if (isLocalStorageAvailable()) {
    //   //   localStorage.removeItem("token");
    //   //   localStorage.removeItem("expires");
    //   // }
    //   state.isAuth = false;
    //   state.tokens = initialState.tokens;
    //   state.status = 'sessionExpired';
    //   state.error = 'sessionExpired';
    // },
    setRefreshTokenRes: (state, action) => {
      if (action.payload) {
        state.tokens = { ...state.tokens, ...action.payload };
      }
    },
    setProfilePicture: (state, action) => {
      if (action.payload) {
        state.data.profile_picture = action.payload;
      }
    }
  },

  extraReducers: {
    // login

    [login.pending]: (state) => {
      state.status = 'loggingIn';
      state.ui.login.loading = true;
    },

    [login.fulfilled]: (state, action) => {
      console.log('user data', action);
      state.data = action.payload?.data?.user;
      state.status = 'idle';
      state.ui.login.loading = false;
      state.tokens.access = action.payload?.data?.access_token;
      state.tokens.refresh = action.payload?.data?.refresh_token;
      state.isAuth = true;
    },

    [login.rejected]: (state, action) => {
      state.status = 'error';
      state.error = action.error.message;
      state.ui.login.loading = false;
    },

    [reCaptchaVarify.pending]: (state) => {
      state.status = 'loggingIn';
      state.ui.login.loading = true;
    },

    [reCaptchaVarify.fulfilled]: (state, action) => {
      console.log('ghddcf', action);
      state.reCaptchaVerify = action.payload;
      state.show = true;
      state.status = 'idle';
      state.ui.login.loading = false;
    },

    [reCaptchaVarify.rejected]: (state, action) => {
      state.status = 'error';
      state.error = action.error.message;
      state.ui.login.loading = false;
    },

    // resetPassword
    [resetPassword.pending]: (state) => {
      state.status = 'resetingPassword';
      state.ui.login.loading = true;
      state.ui.error = '';
      state.ui.msg = '';
    },

    [resetPassword.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.ui.msg = action.payload.msg;
      state.ui.login.loading = false;
    },

    [resetPassword.rejected]: (state, action) => {
      state.status = 'idle';
      state.ui.error = action.error.message;
      state.ui.login.loading = false;
    },
    [register.pending]: (state) => {
      state.status = 'init';
      state.ui.login.loading = true;
    },
    [register.fulfilled]: (state) => {
      state.status = 'idle';
      state.ui.login.loading = false;
    },
    [register.rejected]: (state, action) => {
      state.status = 'error';
      state.error = action.error.message;
      state.ui.login.loading = false;
    },
    [sendResetLink.pending]: (state) => {
      state.status = 'init';
      state.ui.login.loading = true;
    },
    [sendResetLink.fulfilled]: (state) => {
      state.status = 'idle';
      state.ui.login.loading = false;
    },
    [sendResetLink.rejected]: (state, action) => {
      state.status = 'error';
      state.error = action.error.message;
      state.ui.login.loading = false;
    }
  }
});

const { setRefreshTokenRes, persistLogin, logout, setProfilePicture } = authSlice.actions;

export default authSlice.reducer;

export {
  setRefreshTokenRes,
  persistLogin,
  login,
  logout,
  resetPassword,
  reCaptchaVarify,
  getAuthState,
  setProfilePicture,
  register,
  sendResetLink
};
