import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  // InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  // OutlinedInput,
  Select,
  TextField
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './Styles';
import { SearchOutlined } from '@ant-design/icons';
import useDrawerConfig from 'hooks/WebConfig/useDrawerConfig';

const containsText = (text, searchText) => text.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const Language = () => {
  const { LanguageTitle, LanguageData, SelectedLanguage, setLanguageAction } = useDrawerConfig();
  const [personName, setPersonName] = useState([]);
  const [Data, setData] = useState([]);
  const [All, setAll] = useState(true);
  const [searchText, setSearchText] = useState('');
  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    if (SelectedLanguage === null) {
      if (LanguageData) {
        const AddObj = LanguageData?.map((_el) => ({ name: _el, value: true }));
        const displayedOptions = AddObj.filter((option) => containsText(option, searchText));
        setData(displayedOptions);
        const selectedNames = AddObj.filter((item) => item.value === true).map((item) => item.name);
        setPersonName(selectedNames);
        setLanguageAction(LanguageData);
      }
    } else if (LanguageData && SelectedLanguage) {
      const AddObj = LanguageData.map((_el) => ({ name: _el, value: SelectedLanguage.includes(_el) }));
      const displayedOptions = AddObj.filter((option) => containsText(option, searchText));
      setData(displayedOptions);
      const selectedNames = AddObj.filter((item) => item.value === true).map((item) => item.name);
      setPersonName(selectedNames);
    }
  }, [LanguageData, searchText, SelectedLanguage]);

  useEffect(() => {
    if (Data.length > 0) {
      const allTrue = Data.every((_el) => _el.value === true);
      const allFalse = Data.some((_el) => _el.value === false);
      console.log(allFalse);
      setAll(allTrue);
    }
  }, [Data]);

  const handleAllChange = () => {
    const rechk = Data.map((_el) => ({ ..._el, value: !All }));
    setPersonName(!All ? rechk.map((obj) => obj.name) : []);
    setLanguageAction(!All ? rechk.map((obj) => obj.name) : []);
    setData(rechk);
  };

  const handleSelect = (data) => {
    const rechk = Data.map((_el) => {
      if (_el.name === data) {
        _el.value = !_el.value;
      }
      return _el;
    });
    setData(rechk);
  };

  const shouldRenderSelectAll = (LanguageData, Data) => LanguageData?.length > 0 && Data?.length > 0;

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setPersonName(typeof value === 'string' ? value.split(',') : value);
    setLanguageAction(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <FormControl sx={{ width: 156, ml: 1 }}>
      <InputLabel id="demo-multiple-checkbox-label">{LanguageTitle || 'Call Language'}</InputLabel>
      <Select
        sx={{
          backgroundColor: theme.palette.selectbackGround.main,
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '& .MuiTypography-root': {
            fontSize: '12px',
            fontWeight: 500
          }
        }}
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        className={classes.inputContainer}
        value={personName}
        onChange={handleChange}
        // input={<OutlinedInput label={LanguageTitle} />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={{
          PaperProps: {
            style: {
              borderRadius: '10px',
              maxHeight: '300px'
            }
          }
        }}
      >
        <ListSubheader className={classes.listSubheader}>
          <TextField
            variant="standard"
            size="small"
            autoFocus
            placeholder="Search name..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              )
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
            className={classes.textField}
          />
        </ListSubheader>

        {shouldRenderSelectAll(LanguageData, Data) && (
          <MenuItem>
            <FormControlLabel
              sx={{ display: 'flex', flexDirection: 'row', m: 0 }}
              control={<Checkbox checked={All} onChange={handleAllChange} />}
              label="Select All"
            />
          </MenuItem>
        )}

        {Data?.map((name) => (
          <MenuItem key={name.name} value={name.name}>
            <Checkbox checked={name.value} onChange={() => handleSelect(name.name)} />
            <ListItemText primary={name.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Language;
