// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import indexDBStorage from 'redux-persist-indexeddb-storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';

// project import
import menu from 'store/reducers/menu';
import snackbar from 'store/reducers/snackbar';
import authReducer from 'store/auth';
import WebConfigReducer from 'store/Web-Config';
import InboxReducer from 'store/inbox';

const VERSION = 1;
const STORE_NAME = 'experiense_' + VERSION;

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_SECRET_KEY || 'dev_' + STORE_NAME,
  onError: function (error) {
    // Handle the error.
    console.error('encryptTransform', error);
  }
});

const persistConfig = {
  key: 'root',
  version: VERSION,
  storage: indexDBStorage(STORE_NAME),
  // whitelist: [
  //   'auth',
  // ],
  blacklist: ['feedback'],
  transforms: [encryptor]
};

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  auth: authReducer,
  WebConfig: WebConfigReducer,
  inbox: InboxReducer
});

const root = (state, action) => {
  if (action.type === 'auth/logout') {
    localStorage.removeItem('qp-inital-load');
    state = undefined;
  }
  return reducers(state, action);
};
// export default persistReducer(persistConfig, reducers);
export default persistReducer(persistConfig, root);
