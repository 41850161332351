import React from 'react';
import useLocalStorage from 'hooks/useLocalStorage';
// import useContacts from 'hooks/Contacts';
// import useList from 'hooks/List/useList';
// import useAgent from 'hooks/Agent/useAgent';
// import useCampaigns from 'hooks/Campaigns';
// import useRecordings from 'hooks/Recordings/useRecordings';
// import useReporting from 'hooks/Reporting/useReporting';
// import useTestcalls from 'hooks/Testcall/useTestcalls';
// import usePricing from 'hooks/Pricing';

function FirstLoad() {
  // const { filter: list, getListConfigDataAction } = useList();
  // const { filter: contacts, getContactsConfigAction } = useContacts();
  // const { filter: agent, getAgentConfigDataAction, getAgentConfigActionsDataAction } = useAgent();
  // const { filter: campaigns, getCampaignsConfigDataAction } = useCampaigns();
  // const { filter: recordings, getRecordingsConfigAction } = useRecordings();
  // const { filter: reporting, getReportingConfigDataAction } = useReporting();
  // const { filter: testcalls, getTestcallsConfigDataAction } = useTestcalls();
  // const { filter: pricing, getInvoiceWebConfigAction } = usePricing();

  const [config, setConfig] = useLocalStorage('qp-inital-load', false);
  React.useLayoutEffect(
    () => {
      if (!config) {
        // if (!list) getListConfigDataAction();
        // if (!contacts) getContactsConfigAction();
        // if (!agent) {
        //   getAgentConfigDataAction();
        //   getAgentConfigActionsDataAction();
        // }
        // if (!campaigns) getCampaignsConfigDataAction();
        // if (!recordings) getRecordingsConfigAction();
        // if (!reporting) getReportingConfigDataAction();
        // if (!testcalls) getTestcallsConfigDataAction();
        // if (!pricing) getInvoiceWebConfigAction();

        setConfig(true);
      }
    },
    [
      /* list, contacts, agent, campaigns, recordings, reporting, testcalls, pricing */
    ]
  );
  return <React.Fragment></React.Fragment>;
}

export default FirstLoad;
